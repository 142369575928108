// const paymentOptions = {
//     Hora: 'hora',
//     Dia: 'dia',
//     Semanal: 'semanal',
//     Quincenal: 'quincenal',
//     Mensual: 'mensual',
//     Bimestral: 'bimestral',
//     Trimestral: 'trimestral',
//     Semestral: 'semestral',
//     Anual: 'anual'
// }

const paymentOptions = [
    'hora',
    'dia',
    'semanal',
    'quincenal',
    'mensual',
    'bimestral',
    'trimestral',
    'semestral',
    'anual'
]

export default paymentOptions