import React from 'react'
import './index.scss'

const Footer = () =>
    <div>
        <div className="line">
        </div>
        <div className="text-container">
            Powered by Actosoft 2019
        </div>
    </div>

export default Footer